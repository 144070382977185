import { useRef, useState, useEffect } from 'react';
import { Box, Spinner } from 'theme-ui';

import { Link, ShopifyMedia } from '@snippets';

import { useBreakpointValue } from '@hooks';
import { themed } from './Media.theme';
import { QuickAdd } from '../QuickAdd';

export const Media = themed(
  ({
    theme,
    selectedProduct,
    invertImages = false,
    handleClick,
    onMouseEnter = () => null,
    onMouseLeave = () => null,
    productUrl,
  }) => {
    const [hovered, setHovered] = useState(false);
    const videoRef = useRef();

    const media = selectedProduct?.media;
    const firstMedia = media?.[0];
    const secondMedia = media?.[1];

    const [defaultMedia, hoverMedia] = invertImages
      ? [secondMedia, firstMedia]
      : [firstMedia, secondMedia];

    const showQuickAdd = useBreakpointValue([false, true]);

    useEffect(() => {
      if (hovered) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    }, [hovered]);

    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Link
          data-comp={Media.displayName}
          href={productUrl}
          sx={theme.link}
          onClick={handleClick}
        >
          <Box
            data-comp={Media.displayName}
            sx={{
              ...theme.wrapper,
              ':before': {
                content: '""',
                paddingBottom: `${(1 / 0.75) * 100}%`,
                width: 0,
              },
            }}
            onMouseEnter={() => {
              onMouseEnter();
              setHovered(true);
            }}
            onMouseLeave={() => {
              onMouseLeave();
              setHovered(false);
            }}
          >
            {/* hover media */}
            {hoverMedia ? (
              <Box
                data-comp={`${Media.displayName}Hover`}
                sx={{
                  ...theme.hoverMedia,
                  opacity: hovered ? 1 : 0,
                  transition: 'opacity .3s ease-in-out',
                  '@media screen and (max-width: 479px)': {
                    display: 'none',
                  },
                }}
              >
                <ShopifyMedia
                  media={hoverMedia}
                  autoplay={false}
                  ref={videoRef}
                />
              </Box>
            ) : null}

            {/* media */}
            <Box
              data-comp={`${Media.displayName}Default`}
              sx={{
                ...theme.media,
                transition: 'opacity .2s ease-in-out',
                '@media screen and (min-width: 480px)': {
                  opacity: hovered && hoverMedia ? 0 : 1,
                },
              }}
            >
              {defaultMedia ? (
                <ShopifyMedia media={defaultMedia} />
              ) : (
                <Spinner sx={theme.spinner} />
              )}
            </Box>
          </Box>
        </Link>

        {showQuickAdd && <QuickAdd selectedProduct={selectedProduct} />}
      </Box>
    );
  }
);

Media.displayName = 'Media';
