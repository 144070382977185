import { color, button, text, margin, padding } from '@settings/common';

const upSells = () => ({
  label: 'Upsells Tier',
  name: 'upsellsTiers',
  component: 'group',
  fields: [
    {
      label: 'Enable Upsells',
      name: 'upSellsEnabled',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'AutoOpen Upsells',
      name: 'autoOpen',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Discount Type',
      name: 'discountType',
      component: 'radio-group',
      direction: 'horizontal',
      description:
        'If Cart Subtotal is selected, you will need to enable TieredCartDiscountBySpendCampaign in cart script. In addition, spend value set in site settings must match in cart script',
      variant: 'radio',
      options: [
        {
          value: 'upsell',
          label: 'Upsell',
        },
        {
          value: 'cartSubtotal',
          label: 'Cart Subtotal',
        },
      ],
      defaultValue: 'upsell',
    },
    {
      label: 'Hide Inactive Tier Rewards',
      name: 'hideInactiveRewards',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Tiers',
      name: 'tiers',
      component: 'group-list',
      description: 'Create upsell tiers and add products to them.',
      itemProps: {
        label: '{{item.heading}}',
      },
      defaultItem: {
        tierEnabled: true,
        heading: 'Tier 1',
        products: [
          {
            product: { handle: 'black-tech-mask' },
          },
        ],
        lockTier: 'Add {value} more to unlock this tier.',
        unlockTier: 'Congratulations! You qualify for this tier!',
        value: 100,
      },

      fields: [
        {
          label: 'Enable tier',
          name: 'tierEnabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Upsells heading',
          name: 'heading',
          component: 'text',
          defaultValue: 'Tier 1',
        },
        {
          name: 'products',
          label: 'Products',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          validate: {
            maxItems: 3,
          },
          defaultItem: {
            product: { handle: 'black-tech-mask', price: 10 },
          },
          fields: [
            {
              name: 'price',
              component: 'number',
              label: 'Price',
            },
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
          defaultValue: [
            {
              product: { handle: 'black-tech-mask', price: 10 },
            },
          ],
        },
        {
          label: 'Lock tier message',
          description:
            'Message to display when tier is locked. \n Keep the string  {value} to display the value and wrap text in <b></b> tags to update text with accent color',
          name: 'lockTier',
          component: 'text',
          defaultValue: 'Add {value} more to unlock this tier.',
        },
        {
          label: 'Total spend value',
          description: 'Total spend value to unlock tier.',
          name: 'value',
          component: 'number',
          defaultValue: 100,
        },
        {
          label: 'Complete message',
          description: 'Message to display when tier is complete.',
          name: 'unlockTier',
          component: 'text',
          defaultValue: 'Congrats! You qualified for Tier 1 rewards!',
        },
      ],
      defaultValue: [
        {
          heading: 'Tier 1',
          tierEnabled: true,
          value: 100,
          lockTier: 'Add {value} more to unlock this tier.',
          unlockTier: 'Congrats! You qualified for Tier 1 rewards!',
          products: [
            {
              product: { handle: 'black-tech-mask' },
            },
          ],
        },
      ],
    },
    {
      label: 'Price Accent Color',
      name: 'priceAccentColor',
      component: 'color',
    },
  ],
  defaultValue: {
    upSellsEnabled: true,
    hideInactiveRewards: true,
    tiers: [
      {
        heading: 'Tier 1',
        tierEnabled: true,
        value: 100,
        lockTier: 'Add {value} more to unlock this tier.',
        unlockTier: 'Congrats! You qualified for Tier 1 rewards!',
        products: [
          {
            product: { handle: 'black-tech-mask' },
          },
        ],
      },
      {
        heading: 'Tier 2',
        tierEnabled: true,
        value: 150,
        lockTier: 'Add {value} more to unlock tier 2 rewards.',
        unlockTier: 'Congrats! You qualified for Tier 2 rewards!',
        products: [
          {
            product: { handle: 'white-patch-all-day-hat' },
          },
        ],
      },
    ],
  },
});

const upSell = () => ({
  label: 'Upsell Settings',
  name: 'upsell',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enable upsell',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'textSettings',
      label: 'Text Settings',
      component: 'group',
      fields: [
        {
          name: 'message',
          component: 'text',
          label: 'Message w/ active cart',
          defaultValue: 'You might also like...',
        },
        {
          component: 'select',
          name: 'textColor',
          label: 'Text color',
          options: [{ label: 'Default', value: '' }, ...color.options],
          defaultValue: color.value.black,
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
          defaultValue: text.value.h6,
        },
        {
          component: 'select',
          name: 'textPosition',
          label: 'Position',
          options: [
            { label: 'Left', value: 'left' },
            { label: 'Center', value: 'center' },
            { label: 'Right', value: 'right' },
          ],
        },
        {
          name: 'py',
          label: 'Vertical padding',
          component: 'select',
          options: padding.options,
        },
      ],
    },
    {
      name: 'ctaSettings',
      label: 'CTA settings',
      component: 'group',
      fields: [
        {
          name: 'text',
          component: 'text',
          label: 'Cta text',
          defaultValue: 'Add',
        },
        {
          name: 'style',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
      ],
    },
    {
      name: 'mt',
      label: 'Margin top ',
      component: 'select',
      options: margin.options,
    },
    {
      name: 'limit',
      component: 'number',
      label: 'Number of upsell products',
      defaultValue: 5,
    },
    {
      name: 'tag',
      label: 'Searchspring Product Recs Tag',
      component: 'text',
      description:
        'The product recommendations profile tag taken from Searchspring admin, e.g. "cross-sell", "similar", "home", "recently-viewed"',
      defaultValue: 'view-cart',
    },
  ],
  defaultValue: {
    enabled: true,
    textSettings: {
      message: 'You might also like...',
      textColor: color.value.black,
      textVariant: text.value.h6,
      textPosition: 'center',
      mt: '0',
      py: '2',
    },
    ctaSettings: {
      text: 'Add',
      style: 'primary',
      textVariant: '',
    },
    bgColor: color.value.background,
    limit: 5,
    tag: 'view-cart',
  },
});

const freeShippingProgress = () => ({
  label: 'Free shipping progress',
  name: 'freeShippingProgress',
  component: 'group',
  fields: [
    {
      label: 'Toggle free shipping progress',
      name: 'progressEnabled',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Free shipping heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'total spend value',
      name: 'value',
      component: 'number',
    },
    {
      label: 'Complete message',
      name: 'complete',
      component: 'text',
    },
  ],
  defaultValue: {
    progressEnabled: true,
    heading: 'SPEND $100+ FOR FREE U.S. SHIPPING',
    value: 100,
    complete: 'Congrats! You qualified for FREE US shipping!',
  },
});
const cartBanner = () => ({
  label: 'Cart Banner Settings',
  name: 'cartBanner',
  component: 'group',
  fields: [
    {
      label: 'Enable Cart Banner',
      name: 'enabled',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Heading settings',
      name: 'heading',
      description: 'Heading text, position and color',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'text',
          component: 'text',
          defaultValue: 'Get VIP Treatment with a Membership!',
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color ',
          description: 'Choose a color for the heading text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
        },
      ],
    },
    {
      label: 'Description settings',
      name: 'description',
      description: 'description text, position and color',
      component: 'group',
      fields: [
        {
          label: 'Description',
          name: 'text',
          component: 'markdown',
          defaultValue:
            'This VIP Membership doesn’t just pay for itself; it pays you back in style and savings.',
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color ',
          description: 'Choose a color for the description text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
        },
      ],
    },
    {
      label: 'Dynamic Message Settings',
      name: 'dynamicMessage',
      description: 'description text, position and color',
      component: 'group',
      fields: [
        {
          label: 'Dynamic Message',
          name: 'text',
          component: 'text',
          description:
            'Add a dynamic message to the banner. Between /bold italic/, Use {value} to display the value.',
          defaultValue: 'Sign up and save {value} on this purchase!',
        },
        {
          label: 'Dynamic Message discount Value',
          name: 'value',
          component: 'number',
          description:
            'Add a the percentage to the dynamic message, this will be displayed in the banner.',
          defaultValue: 20,
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color ',
          description: 'Choose a color for the dynamic message text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'dynamicPriceColor',
          label: 'Dynamic Price color ',
          description: 'Choose a color for the dynamic price only text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
        },
      ],
    },
    {
      label: 'Price',
      name: 'price',
      component: 'text',
      description: 'Add a custom price for the membership. e.g. 10/mo',
      defaultValue: '10/mo',
    },
    {
      label: 'Use product price',
      name: 'useProductPrice',
      component: 'toggle',
      description: 'Use the price of the selected product for the membership.',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Cart banner image',
      name: 'image',
      component: 'image',
      defaultValue: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/Artboard_202_20_284_29_ceaac49a-9218-4d4e-8f3b-41b19844bda0_768x_crop_center@2x.progressive.jpg?v=1712771983',
        alt: 'Membership Banner',
      },
    },
    {
      label: 'Use Link for CTA',
      name: 'useLink',
      component: 'toggle',
      description: 'Use the price of the selected product for the membership.',
      toggleLabels: {
        true: 'use link',
        false: 'use add to cart button',
      },
      defaultValue: false,
    },
    {
      label: 'Cart banner link',
      name: 'cta',
      component: 'link',
    },
    {
      label: 'Button style',
      name: 'style',
      component: 'select',
      options: button.options,
    },
    {
      label: 'Membership Product',
      name: 'membershipProduct',
      component: 'productSearch',
    },
  ],
});

const preOrder = () => ({
  label: 'Pre Order',
  name: 'preOrder',
  component: 'group',
  fields: [
    {
      label: 'Pre Order Item Message',
      name: 'message',
      component: 'text',
      defaultValue: 'Pre-order Item',
    },
    {
      name: 'textColor',
      label: 'Text Color',
      component: 'color',
    },
    {
      name: 'maxQty',
      label: 'Max Quantity',
      component: 'number',
      defaultValue: 5,
    },
  ],
});

const cashback = () => ({
  label: 'Cashback Product',
  name: 'cashback',
  component: 'group',
  fields: [
    {
      name: 'cashbackProducts',
      label: 'Cashback Products',
      component: 'group-list',
      itemProps: {
        label: '{{item.product.handle}}',
      },
      fields: [
        {
          label: 'Cashback Product',
          name: 'product',
          component: 'productSearch',
        },
      ],
    },
    {
      name: 'widgetEnabled',
      label: 'Enable Cashback Widget',
      component: 'toggle',
      description: 'Enable or disable the cashback widget',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'cashbackMessage',
      label: 'Cashback Widget text',
      component: 'textarea',
      description:
        'Text to display in the cashback widget. {value}  will be replaced with the cashback value',
      defaultValue: 'You get {value} after your purchase to use anywhere.',
    },
  ],
});
export default {
  label: 'Cart Settings',
  name: 'cart',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Your Shopping Cart',
    },
    {
      label: 'Shipping, taxes text',
      name: 'shippingText',
      component: 'text',
      defaultValue: 'Shipping, taxes and discounts calculated at checkout.',
    },
    {
      label: 'Discounts text',
      name: 'discountsText',
      component: 'text',
      defaultValue: 'Discounts will reflect in checkout',
    },
    {
      label: 'Cart empty settings',
      name: 'empty',
      component: 'group',
      fields: [
        {
          label: 'Message',
          name: 'message',
          component: 'markdown',
          defaultValue: 'Your cart is currently empty.',
        },
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
          defaultValue: {
            text: 'SHOP ALL',
            url: '/collections/all',
          },
        },
      ],
    },
    preOrder(),
    freeShippingProgress(),
    upSells(),
    upSell(),
    cartBanner(),
    cashback(),
  ],
};
