import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Paragraph,
} from 'theme-ui';

import { useCartAddItems, useProductByHandle } from '@backpackjs/storefront';
import { useBreakpoint } from '@hooks';
import { Markdown } from '@snippets/Markdown';

import store, { useRecoilCallback } from '@store';
import { ProductItem } from './ProductItem';

export const VipProduct = ({
  selectedVariant,
  settings,
  product,
  closeModal,
}) => {
  const {
    heading,
    description,
    subheading,
    showProduct,
    useLink,
    cta,
    buttonStyle,
    membershipProduct,
    secondaryButton,
    secondaryButtonStyle,
  } = settings?.product?.vipModal || {};

  const { s } = useBreakpoint();
  const isMobile = s;

  const { product: fullProduct } = useProductByHandle({
    handle: membershipProduct?.handle,
  });
  const [vipMembershipProduct, setVipMembershipProduct] = useState(fullProduct);

  const { cartAddItems, ...status } = useCartAddItems();

  useEffect(() => {
    setVipMembershipProduct(fullProduct);
  }, [fullProduct?.id]);

  console.log({ fullProduct, membershipProduct });

  const toggleCartSidebar = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot
            .map(({ set }) => set(store.modal, null))
            .map(({ set }) => set(store.modalProps, {}))
            .map(({ set }) => set(store.overlay, true))
            .map(({ set }) => set(store.sidebar, 'cart'));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  const handleAddProductsToCart = useCallback(async () => {
    const inveterateProduct = {
      merchandiseId: vipMembershipProduct?.variants?.[0].id,
      quantity: 1,
      sellingPlanId:
        vipMembershipProduct?.variants?.[0]?.sellingPlanAllocations?.[0]
          .sellingPlan?.id,
      attributes: [{ key: 'membership', value: 'true' }],
    };
    console.log({
      inveterateProduct,
      fullProduct,
    });

    const itemsToAdd = [
      inveterateProduct,
      {
        merchandiseId: selectedVariant?.id,
        quantity: 1,
        attributes: [{ key: 'vip product', value: 'true' }],
      },
    ];
    console.log({ itemsToAdd });
    try {
      const cart = await cartAddItems(itemsToAdd);
      if (cart) {
        toggleCartSidebar();
      }
    } catch (error) {
      console.log({
        error,
      });
    }
  }, []);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
      }}
    >
      <Flex
        sx={{
          flexBasis: ['20%', '40%'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          sx={{
            display: ['none', 'block'],
            width: 'auto',
            height: '100%',
            objectFit: 'cover',
          }}
          src={fullProduct?.images?.[0]?.src}
        />
      </Flex>
      <Flex
        sx={{
          borderRadius: '4px',
          mx: 8,
          my: 4,
          p: [4, 8],
          color: 'white',
          justifyContent: 'space-between',
          position: 'relative',
          flexDirection: 'column',
          flexBasis: '60%',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <Heading
            as="h2"
            sx={{
              variant: `text.${heading?.textVariant}`,
              color: 'black',
              my: 4,
              textAlign: 'center',
            }}
          >
            {heading?.text}
          </Heading>

          <Paragraph
            sx={{
              variant: `text.${subheading?.textVariant}`,
              color: subheading?.color,
              mr: [4, 0],
              textAlign: 'center',
            }}
          >
            {subheading?.text}
          </Paragraph>
          <Markdown
            text={description?.text}
            sx={{
              variant: `text.${description?.textVariant}`,
              color: description?.color,
              textAlign: 'center',
            }}
            textSx={{
              li: { textWrap: ['nowrap', 'wrap'] },
            }}
          />
        </Flex>
        <Flex
          sx={{
            my: 2,
          }}
        >
          {isMobile && (
            <ProductItem
              productItem={fullProduct}
              selectedVariant={fullProduct?.variants?.[0]}
            />
          )}
        </Flex>
        <Flex
          sx={{
            my: [2, 4],
          }}
        >
          {showProduct && !isMobile && (
            <ProductItem
              productItem={product}
              selectedVariant={selectedVariant}
            />
          )}
        </Flex>

        <Flex
          sx={{
            flexDirection: ['column-reverse', 'row', 'row'],
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 4,
            width: '100%',
            py: 4,
          }}
        >
          <Button
            variant={`buttons.${secondaryButtonStyle || 'secondary'}`}
            onClick={closeModal}
          >
            {secondaryButton || 'No thanks'}
          </Button>

          <Flex>
            {useLink ? (
              <Link
                href={cta?.url}
                variant={`buttons.${buttonStyle || 'primary'}`}
                sx={{}}
              >
                {cta?.text}
              </Link>
            ) : (
              <Button
                onClick={handleAddProductsToCart}
                variant={`buttons.${buttonStyle || 'primary'}`}
                sx={{}}
              >
                Add to Cart
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
