import { useCallback, useMemo } from 'react';
import store, { useRecoilCallback, useGotoRecoilSnapshot } from '@store';
import {
  useCartAddItem,
  useCartItems,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';

import { useSelectedPlan } from '@hooks';

export const useAddToCart = ({ selectedVariant, product }) => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const [selectedPlan] = useSelectedPlan({ selectedVariant });
  const cartItems = useCartItems();
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle || product?.handle,
    withQuantity: true,
  });
  const preOrder = product?.tags?.includes('pre-order');
  const isInveterate = product?.tags?.includes('inveterate-product');
  const id = useMemo(() => {
    return Object.keys({ ...inventory?.variants })[0]?.startsWith('gid://')
      ? selectedVariant?.id
      : selectedVariant?.storefrontId;
  }, [inventory?.variants, selectedVariant?.id]);

  // use live inventory data
  const variantInventory = inventory ? inventory?.variants?.[id] : null;

  const isDigitalProduct = product?.productType === 'Gift Cards';

  const isSoldOut = variantInventory
    ? variantInventory.quantityAvailable <= 0 &&
    selectedVariant?.inventoryPolicy !== 'CONTINUE'
    : false;

  const isPreOrder =
    !isInveterate &&
    variantInventory?.quantityAvailable <= 0 &&
    selectedVariant?.inventoryPolicy === 'CONTINUE' &&
    !isDigitalProduct;

  const toggleCartSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.modalProps, {}))
        .map(({ set }) => set(store.overlay, true))
        .map(({ set }) => set(store.sidebar, 'cart'));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  // add oneTime and subs
  const addToCart = useCallback(
    async ({ attributes } = { attributes: null }) => {
      console.log('addToCart');
      if (!selectedVariant || (isSoldOut && !preOrder)) return;

      // cancel request if cart contains vip membership product
      if (
        cartItems?.some(
          (item) => item.variant?.product?.handle === 'vip-membership'
        ) &&
        selectedVariant?.product?.handle === 'vip-membership'
      ) {
        toggleCartSidebar();
        return;
      }

      const item = {
        merchandiseId: selectedVariant.id,
        quantity: 1,
        ...(attributes ? { attributes } : null),
      };

      // if selling plan is selected we the subscription
      if (selectedPlan) {
        item.sellingPlanId = selectedPlan.id;
      }

      // forces inveterate membership product to be added as a selling plan
      if (
        selectedVariant?.product?.handle === 'inveterate-subscription' ||
        attributes?.some(
          (attr) => attr.key === '_recharge' && attr.value === 'true'
        )
      ) {
        item.sellingPlanId =
          selectedVariant?.sellingPlanAllocations?.[0].sellingPlan?.id;
      }

      // if product contains bundle and product contains packqty::, add line item properties
      if (product?.tags?.includes('bundle')) {
        const [, packqty] =
          product.tags
            .find((tag) => tag.indexOf('packqty') > -1)
            ?.split('::') || [];
        if (packqty) {
          item.attributes = [
            ...(item.attributes || []),
            { key: '_packqty', value: packqty },
            { key: '_bundle', value: 'true' },
          ];
        }
      }

      if (preOrder) {
        item.attributes = [
          ...(item.attributes || []),
          { key: 'pre-order', value: 'true' },
        ];
      }

      const cart = await cartAddItem(item);

      if (cart) {
        toggleCartSidebar();
      }
    },
    [selectedVariant?.id, isSoldOut, selectedPlan?.id, cartItems?.updatedAt]
  );

  return [
    {
      isPreOrder,
      isSoldOut,
      selectedPlan,
      status: {
        addItem: addItemStatus,
        inventory: inventoryStatus,
      },
    },
    {
      addToCart,
      toggleCartSidebar,
    },
  ];
};
