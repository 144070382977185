import { useCallback, useMemo, useState } from 'react';
import { Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';

import { Badges } from '@snippets/Badges';
import {
  useDataLayerActions,
  useIntelliSuggest,
  useLocalizedSelectedVariant,
  useSwatchColors,
} from '@hooks';

import { Media } from './Media';
import { Details } from './Details';
import { themed } from './ProductItem.theme';

export const ProductItem = themed(
  ({
    theme,
    product,
    showBadges = true,
    badgeVariant = 'primary',
    badgeDisplay = 'product',
    invertImages = false,
    index,
    isSearchPage,
    searchTerm,
    viewPerRow,
    isSearchResults = false,
    ...props
  }) => {
    if (!product) return null;
    const swatches = useSwatchColors();
    const { ref, inView } = useInView({
      rootMargin: '400px',
      triggerOnce: true,
    });
    const { product: initialProduct } = useProductByHandle({
      handle: product.handle,
      fetchOnMount: inView,
    });

    const [productFromColorSelector, setProductFromColorSelector] =
      useState(null);
    const [variantFromColorSelector, setVariantFromColorSelector] =
      useState(null);

    const selectedProduct = useMemo(() => {
      return productFromColorSelector || initialProduct;
    }, [productFromColorSelector?.id, initialProduct?.id]);

    const selectedVariant = useMemo(() => {
      return variantFromColorSelector || selectedProduct?.variants?.[0];
    }, [variantFromColorSelector?.id, selectedProduct?.id]);

    // Cuts specific only
    const selectedVariantForLocalized = useMemo(() => {
      return selectedVariant
        ? {
            ...selectedVariant,
            selectedOptions: selectedVariant.selectedOptions?.filter(
              ({ name }) => name !== 'color'
            ),
          }
        : null;
    }, [selectedVariant]);

    const { intelliSuggestTrackClick } = useIntelliSuggest(product);

    const { sendClickProductItemEvent } = useDataLayerActions();

    const { localized } = useLocalizedSelectedVariant({
      variant: product?.variants?.[0],
    });

    const handleClick = useCallback(() => {
      const defaultVariant = initialProduct?.variants?.[0];
      PubSub.publish('CLICK_COLLECTION_ITEM', {
        ...defaultVariant,
        image: initialProduct?.images?.[0],
        index,
      });
      sendClickProductItemEvent({
        isSearchResults,
        listIndex: index,
        localized,
        product,
        selectedVariant: product?.variants?.[0],
      });
    }, [index, initialProduct?.id]);

    const productUrl = selectedProduct
      ? `/products/${selectedProduct.handle}`
      : '';

    return (
      <Box
        data-comp={ProductItem.displayName}
        ref={ref}
        {...props}
        sx={{
          ...theme.wrapper,
          ...props.sx,
        }}
      >
        {showBadges && (
          <Badges
            product={selectedProduct}
            selectedVariant={selectedVariant}
            display={badgeDisplay}
            variant={badgeVariant}
            productBadgeStyle={theme.badge}
            badgeWrapperStyle={theme.badgeWrapper}
            isCollection
          />
        )}

        <Media
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          initialProduct={initialProduct}
          invertImages={invertImages}
          handleClick={handleClick}
          productUrl={productUrl}
        />

        <Details
          swatches={swatches}
          handleClick={handleClick}
          initialProduct={initialProduct}
          selectedVariant={selectedVariant}
          selectedProduct={selectedProduct}
          setProductFromColorSelector={setProductFromColorSelector}
          setVariantFromColorSelector={setVariantFromColorSelector}
          productUrl={productUrl}
        />
      </Box>
    );
  }
);

ProductItem.displayName = 'ProductItem';
