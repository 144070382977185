import {
  position,
  sectionWithHeight,
  color,
  align,
  button,
  padding,
} from '@settings/common';
import {
  slider,
  text,
  buttons,
  content,
  defaultSlide1,
  defaultSlide2,
  media,
} from './Schemas';

export const Schema = () => {
  return {
    label: 'Hero slider',
    key: 'hero',
    fields: [
      slider({ color }),
      {
        name: 'slides',
        label: 'Slides',
        component: 'group-list',
        itemProps: {
          label: '{{item.text.heading.headingText}}',
        },
        defaultItem: defaultSlide1(),
        fields: [
          media({ position, color }),
          text({ color, align }),
          buttons({ button }),
          content({ position, align, padding }),
        ],
        defaultValue: [defaultSlide1(), defaultSlide2({ color })],
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          mobile: {
            maxHeight: 324,
            minHeight: 324,
          },
          desktop: {
            maxHeight: 600,
            minHeight: 600,
          },
        },
      },
    ],
  };
};
