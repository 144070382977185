import { useEffect } from 'react';
import Script from 'next/script';

export function FueledKlaviyoScript({ withKey: klaviyoPixelId }) {
  /* Specs:
    * https://developers.klaviyo.com/en/docs/javascript_api
    * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration
  */

  useEffect(() => {

    const identify = (event) => {
      // Identify a user
      if (event.detail.user_properties?.customer_email) {
        klaviyo.identify({
          'email' : event.detail.user_properties?.customer_email,
          'first_name' : event.detail.user_properties?.customer_first_name,
          'last_name' : event.detail.user_properties?.customer_last_name,
        });
      }
    };

    const viewItemListListener = (event) => {
      identify(event);

      klaviyo.push(['track', 'Category View',{
        'CategoryName': event.detail.ecommerce.collection_id, // Best we got at the moment.
        'CategoryID': event.detail.ecommerce.collection_id
      }]);
    };

    const viewItemListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.detail.products;

      klaviyo.push(['track', 'Viewed Product',
        {
          ProductName: product.name,
          ProductID: product.product_id,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          CompareAtPrice: product.price
        }
      ]);

      klaviyo.push(['trackViewedItem',
          {
          Title: product.name,
          ItemId: product.product_id,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            CompareAtPrice: product.compare_at_price
          }
        }
      ]);
    };

    const addToCartListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.add.products;

      klaviyo.push([
        'track',
        'Added to Cart',
        {
          Name: product.name,
          ProductID: product.product_id,
          Categories: product.categories,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          Quantity: product.quantity,
          CompareAtPrice: product.price,
        },
      ]);

      klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.product_id,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            Quantity: product.quantity,
            CompareAtPrice: product.compare_at_price,
          },
        },
      ]);
    };

    const klaviyoFormsListener = (e) => {
      if (e.detail.type === 'submit') {
        window.dataLayer.push({
          event: 'dl_subscribe',
          formId: e.detail.formId,
          formTitle: e.detail.metaData.$source,
        });
      }
    };

    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_view_item_list', viewItemListListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);
    window.addEventListener('klaviyoForms', klaviyoFormsListener);

    return () => {
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_view_item_list', viewItemListListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
      window.removeEventListener('klaviyoForms', klaviyoFormsListener);
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      id="klaviyo-script"
      defer
      strategy="beforeInteractive"
      src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPixelId}`}
    />
  );
}