import { useEffect, useState } from 'react';
import { Box, Container, ThemeProvider } from 'theme-ui';

import { MDXProvider, useMDXComponents } from '@mdx-js/react';

import { useThemedStylesWithMdx } from '@theme-ui/mdx';

import { Fueled } from '@snippets/Fueled';

import {
  useRouter,
  useStorefrontInit,
  useCartAddDiscountUrl,
  useCountry,
} from '@backpackjs/storefront';

import {
  Header,
  FridayProjectsHeader,
  Footer,
  Overlay,
  CartSearchSidebarShell,
  Modal,
  GeolocationModal,
  DataLayerEventListeners,
  PostScript,
} from '@snippets';

import {
  useTrackSettings,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useGlobalCustomer,
  usePackCookie,
  useFondueCashback,
} from '@hooks';
import store, { useRecoilValue } from '@store';

import { StorefrontHead } from '@head';

// import GlobalEParams from '@head/GLBEParams';

import StorefrontTheme from '../theme';

const GlobalThemeProvider = ({ children, theme, components }) => {
  const componentsWithStyles = useThemedStylesWithMdx(
    useMDXComponents(components)
  );

  return (
    <ThemeProvider theme={theme}>
      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>
    </ThemeProvider>
  );
};

const StorefrontLayout = ({ ContentForLayout, ...props }) => {
  const { asPath } = useRouter();
  const { country } = useCountry();
  const [currentCountry, setCurrentCountry] = useState(country);
  const currentMainCountry = useRecoilValue(store.country);

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // adds --viewport-height css var to the :root
  useSetViewportHeightCssVar();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // track siteSetting changes in recoil
  useTrackSettings();

  // sets window global obj for tracking events set in GTM
  useGlobalCustomer();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  useFondueCashback();

  const isFridayProjects = asPath.includes('/the-friday-projects');

  useEffect(() => {
    setCurrentCountry(country);
  }, [country, currentMainCountry]);

  // set pack session cookie
  usePackCookie();

  return (
    <GlobalThemeProvider theme={StorefrontTheme}>
      {/* <head> links, scripts... */}
      <StorefrontHead />

      <DataLayerEventListeners {...props} />

      <PostScript />
      <Fueled />
      {isFridayProjects ? <FridayProjectsHeader /> : <Header />}

      {/* main content */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight:
            'calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))',
          alignItems: 'stretch',
        }}
      >
        <Container
          as="main"
          role="main"
          sx={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          <ContentForLayout {...props} key={asPath} />
        </Container>

        <Box
          sx={{
            flexShrink: 0,
            pb: 'var(--sticky-atc-height)',
          }}
        >
          <Footer />
        </Box>
      </Box>

      <Overlay />
      {/*
        This is empty sidebar shell, the content's js
        is dynamically loaded only when opened dynamic
    */}
      <CartSearchSidebarShell />
      <Modal />
      <div id="modal-root" />
      <GeolocationModal />
    </GlobalThemeProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
