import { useMemo } from 'react';
import { getValueFromTag } from '@utils/getValueFromTag';
import { Badge, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';
import { themed } from './Badges.theme';

export const Badges = themed(
  ({
    theme,
    productBadgeStyle,
    fabricBadgeStyle,
    badgeWrapperStyle,
    variant,
    product,
    selectedVariant,
    display, // 'all' | 'product' | 'fabric'
  }) => {
    if (!product) return null;
    const settings = useSettings();
    const showProductBadges = display === 'all' || display === 'product';
    const showFabricBadges = display === 'all' || display === 'fabric';
    const showSaleBadges = display === 'all' || display === 'product';

    const productBadge = getValueFromTag(product, 'badge');
    const fabricBadge = getValueFromTag(product, 'fabricBadge');

    const hasSaleBadge = getValueFromTag(product, 'saleBadge');
    const preOrderTag = getValueFromTag(product, 'Badge');
    const isPreOrder = product?.tags.includes('pre-order');
    const saleBadge = useMemo(() => {
      if (hasSaleBadge === 'true' && selectedVariant) {
        const percentOff = (
          100 -
          (Number(selectedVariant?.priceV2?.amount) /
            Number(selectedVariant?.compareAtPriceV2?.amount)) *
            100
        ).toFixed(0);
        return percentOff;
      }
      return null;
    }, [hasSaleBadge, selectedVariant]);
    const {
      enabled,
      text: preOrderText,
      textColor,
      bgColor,
    } = settings?.product?.preOrder?.preOrderBadge || {};

    return (
      <Flex
        data-comp={Badges.displayName}
        sx={{
          ...theme.badgeWrapper,
          ...badgeWrapperStyle,
          width: '100%',
          justifyContent: 'space-between',
          px: 5,
        }}
      >
        <Flex>
          {showProductBadges && productBadge && (
            <Badge
              variant={`${variant !== 'inverse' ? 'primary' : 'secondary'}`}
              sx={{ ...theme.badge(false), ...productBadgeStyle }}
            >
              {productBadge}
            </Badge>
          )}

          {showFabricBadges && fabricBadge && (
            <Badge
              variant={`${variant !== 'inverse' ? 'secondary' : 'primary'}`}
              sx={{
                ...theme.badge(fabricBadge.toLowerCase()),
                ...fabricBadgeStyle,
              }}
            >
              {fabricBadge}
            </Badge>
          )}
          {isPreOrder && preOrderTag && enabled && (
            <Badge
              variant="preOrder"
              sx={{ ...theme.preOrder, color: textColor, bg: bgColor }}
            >
              {preOrderText || preOrderTag}
            </Badge>
          )}
        </Flex>

        {showSaleBadges && saleBadge && (
          <Badge sx={theme.saleBadge}>{saleBadge}% off</Badge>
        )}
      </Flex>
    );
  }
);

Badges.displayName = 'Badges';
