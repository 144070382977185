import { useCallback, useEffect } from 'react';
import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  useCartItems,
  useCustomer,
  useSettings,
  useCartRemoveItem,
} from '@backpackjs/storefront';
import store, { useSetRecoilState } from '@store';
import { LineItem } from '../LineItem';
import { themed } from './LineItems.theme';

export const LineItems = themed(
  ({ theme: themes, inSidebar = false, ...props }) => {
    const cartItems = useCartItems({ sortByAddedAt: true });
    const theme = inSidebar ? themes.sidebar : themes.page;
    const settings = useSettings();
    const cashbackProductHandle =
      settings?.cart?.cashback?.product?.handle || '15-cashback';
    const customer = useCustomer();

    const { cartRemoveItem } = useCartRemoveItem();

    const isVip = customer?.tags.includes('VIP Membership');

    const isCashbackProduct = (item) => {
      return item.variant.product.handle === cashbackProductHandle;
    };

    const isMembershipProduct = (item) =>
      item.attributes.some(
        ({ key, value }) => key === 'membership' && value === 'true'
      );

    const isVipProduct = (item) =>
      item.attributes.some(
        ({ key, value }) => key === 'vip product' && value === 'true'
      );

    const hasMembershipProduct = cartItems.some(isMembershipProduct);

    const removeInvalidVipProducts = useCallback(async () => {
      try {
        const invalidVipProducts = cartItems.filter(
          (item) => isVipProduct(item) && !isVip && !hasMembershipProduct
        );
        await Promise.all(
          invalidVipProducts.map((item) => cartRemoveItem({ lineId: item.id }))
        );
      } catch (error) {
        console.error('Error removing VIP products:', error);
      }
    }, [cartItems, isVip, hasMembershipProduct, cartRemoveItem]);

    useEffect(() => {
      if (!isVip && !hasMembershipProduct) {
        removeInvalidVipProducts();
      }
    }, [removeInvalidVipProducts, isVip, hasMembershipProduct]);
    const validCartItems = cartItems.filter((item) => {
      if (isVipProduct(item)) {
        return isVip || hasMembershipProduct;
      }
      return true;
    });

    const setAdjustedCartTotal = useSetRecoilState(store.cartTotal);
    const cartItemsDep = validCartItems
      ?.map((item) => item.id + item.quantity)
      .join('');

    useEffect(() => {
      const cartTotal = validCartItems?.reduce((acc, item) => {
        let price = parseFloat(item.estimatedCost?.totalAmount?.amount || '0');
        const isUpsell = item.attributes.some(
          ({ key, value }) => key === 'upsell' && value === 'true'
        );
        if (isUpsell) {
          price = parseFloat(
            item.attributes.find(({ key }) => key === 'upsellTierPrice')
              ?.value || '0'
          );
        }
        return acc + price;
      }, 0);

      setAdjustedCartTotal(
        cartTotal.toString().includes('.')
          ? cartTotal.toFixed(2)
          : `${cartTotal}`
      );
    }, [cartItemsDep]);

    return (
      <Box
        key={LineItems.displayName}
        data-comp={LineItems.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme,
          textAlign: 'right',
        }}
      >
        {validCartItems.map((item, index) => (
          <LineItem
            key={item.id}
            item={item}
            isLast={index === validCartItems.length - 1}
            inSidebar={inSidebar}
            showDiscount={isVip || isMembershipProduct(item)}
            isCashbackProduct={isCashbackProduct(item)}
          />
        ))}
      </Box>
    );
  }
);

LineItems.displayName = 'LineItems';
LineItems.propTypes = {
  inSidebar: PropTypes.bool,
};
