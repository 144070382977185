import {
  position,
  section,
  align,
  button,
  color,
  icons,
  text,
  margin,
} from '@settings/common';

export const Schema = ({ blog, collection }) => {
  if (blog || collection) return null;
  return {
    label: 'Half Media VIP',
    key: 'half-media-vip',
    fields: [
      {
        label: 'Media settings',
        name: 'media',
        description:
          'Select Media Type (image - video), position, placement, aspect ratio',
        component: 'group',
        fields: [
          {
            label: 'Select Media Type',
            description: 'Allows select between image or video',
            name: 'mediaType',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'image',
          },
          {
            label: 'Image settings',
            name: 'image',
            description: 'Image, position, placement, aspect ratio',
            component: 'group',
            fields: [
              {
                label: 'Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of image',
              },
              {
                label: 'Image (mobile)',
                name: 'image',
                component: 'image',
              },
              {
                name: 'position',
                component: 'select',
                label: 'Image position (mobile)',
                options: position.object,
              },
              {
                label: 'Placement (mobile)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'location',
                options: [
                  {
                    value: 'top',
                    label: 'Top',
                  },
                  {
                    value: 'bottom',
                    label: 'Bottom',
                  },
                ],
              },
              {
                label: 'Aspect ratio (mobile)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'ratio',
                options: [
                  {
                    value: '80%',
                    label: '5:4',
                  },
                  {
                    value: '100%',
                    label: '1:1',
                  },
                  {
                    value: '120%',
                    label: '5:6',
                  },
                ],
              },
              {
                label: 'Image center round',
                name: 'roundImage',
                component: 'image',
              },
              {
                name: 'imageDt',
                label: 'Image (desktop)',
                component: 'image',
              },
              {
                name: 'positionDt',
                component: 'select',
                label: 'Image position (desktop)',
                options: position.object,
              },
              {
                label: 'Placement (desktop)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'locationDt',
                options: [
                  {
                    value: 'left',
                    label: 'Left',
                  },
                  {
                    value: 'right',
                    label: 'Right',
                  },
                ],
              },
              {
                label: 'Aspect ratio (desktop)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'ratioDt',
                options: [
                  {
                    value: '80%',
                    label: '5:4',
                  },
                  {
                    value: '100%',
                    label: '1:1',
                  },
                  {
                    value: '120%',
                    label: '5:6',
                  },
                ],
              },
            ],
            defaultValue: {
              location: 'top',
              locationDt: 'left',
              ratio: '100%',
              ratioDt: '100%',
              alt: `Half media image`,
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
              },
              position: 'center center',
              imageDt: {
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
              },
              positionDt: 'center center',
            },
          },
          {
            label: 'Video settings',
            name: 'video',
            description: 'Video, position, placement, aspect ratio',
            component: 'group',
            fields: [
              {
                label: 'Enable Autoplay',
                name: 'autoplay',
                description:
                  'Enable autoplay for video, you need to refresh page after change',
                component: 'toggle',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
                defaultValue: true,
              },
              {
                label: 'Title',
                name: 'title',
                component: 'text',
                description: 'Title of the video',
              },
              {
                label: 'Video Source (mobile)',
                name: 'videoSrcMobile',
                component: 'text',
                description:
                  'Select between a local video or a link to a video (.mp4) \n if you want to use a local video, upload it and let the url here empty',
              },
              {
                name: 'localVideoMobile',
                label: 'Upload Video (mobile)',
                component: 'image',
              },
              {
                label: 'Placement (mobile)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'location',
                options: [
                  {
                    value: 'top',
                    label: 'Top',
                  },
                  {
                    value: 'bottom',
                    label: 'Bottom',
                  },
                ],
              },
              {
                name: 'videoSrcDesktop',
                label: 'Video (desktop)',
                component: 'text',
                description:
                  'Select between a local video or a link to a video (.mp4) \n if you want to use a local video, upload it and let the url here empty',
              },
              {
                name: 'localVideoDesktop',
                label: 'Upload Video (desktop)',
                component: 'image',
              },
              {
                label: 'Placement (desktop)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'locationDt',
                options: [
                  {
                    value: 'left',
                    label: 'Left',
                  },
                  {
                    value: 'right',
                    label: 'Right',
                  },
                ],
              },
            ],
            defaultValue: {
              autoplay: true,
              location: 'top',
              locationDt: 'left',
              title: `Half media video`,
              position: 'center center',
              positionDt: 'center center',
              localVideoMobile: {
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/PDP_LIFESTYLE_AOShort_16x9_01.mp4?v=1650908130',
              },
              localVideoDesktop: {
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/PDP_LIFESTYLE_AOShort_16x9_01.mp4?v=1650908130',
              },
              videoSrcMobile:
                'https://cdn.shopify.com/s/files/1/1368/3463/files/PDP_LIFESTYLE_AOShort_16x9_01.mp4?v=1650908130',
              videoSrcDesktop:
                'https://cdn.shopify.com/s/files/1/1368/3463/files/PDP_LIFESTYLE_AOShort_16x9_01.mp4?v=1650908130',
            },
          },
        ],
        defaultValue: {
          mediaType: 'image',
          image: {
            location: 'top',
            locationDt: 'left',
            ratio: '100%',
            ratioDt: '100%',
            alt: `Half media image`,
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
            },
            position: 'center center',
            imageDt: {
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
            },
            positionDt: 'center center',
          },
        },
      },
      {
        label: 'Content settings',
        name: 'content',
        description: 'Heading, body, link, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Title - Subtitle',
            description: 'Set the title and subtitle you want to display',
            name: 'textSettings',
            component: 'group-list',
            defaultItem: {
              text: '**“The texture is buttery soft and amazing to the touch.”**',
              textColor: color.value.black,
              textSize: text.value.base,
              textSpace: margin.value.none,
            },
            fields: [
              {
                label: 'Text',
                name: 'text',
                component: 'markdown',
                defaultValue:
                  '**“The texture is buttery soft and amazing to the touch.”**',
              },
              {
                label: 'Text color',
                name: 'textColor',
                component: 'select',
                options: color.options,
                defaultValue: color.value.black,
              },
              {
                label: 'Text size',
                name: 'textSize',
                component: 'select',
                options: text.options,
                defaultValue: text.value.h6,
              },
              {
                label: 'Text Margin',
                name: 'textSpace',
                component: 'select',
                options: margin.options,
                defaultValue: margin.value.none,
              },
            ],
            defaultValue: [
              {
                text: '**“The texture is buttery soft and amazing to the touch.”**',
                textColor: color.value.black,
                textSize: text.value.h6,
                textSpace: margin.value.none,
              },
              {
                text: 'Cum sociis natoque penatibus et magnis  montes, nascetur ridiculus mus. Maecenas sed diam eget risus varius blandit sit amet non magna.',
                textColor: color.value.gray_dark,
                textSize: text.value.base,
                textSpace: margin.value.small,
              },
            ],
          },
          {
            label: 'Body Content',
            name: 'body',
            description:
              'Customize the body content of the section \n you can choose between text or icons',
            component: 'group',
            fields: [
              {
                label: 'Select Content Type',
                description: 'Select between text or icons',
                name: 'contentType',
                component: 'radio-group',
                direction: 'horizontal',
                options: [
                  { label: 'Text', value: 'text' },
                  { label: 'Icons', value: 'icons' },
                ],
                defaultValue: 'text',
              },
              {
                label: 'Body Text',
                description: 'Set the text you want to display',
                name: 'text',
                component: 'group',
                fields: [
                  {
                    label: 'Text',
                    name: 'bodyText',
                    component: 'markdown',
                    defaultValue:
                      ' classic curve hem \n • Elevated regular bottom cut \n • Curved hem to eliminate bunching around the waist \n • Traditional fitting body',
                  },
                  {
                    label: 'Text color',
                    name: 'bodyColor',
                    component: 'select',
                    options: color.options,
                    defaultValue: color.value.black,
                  },
                  {
                    label: 'Text size',
                    name: 'bodySize',
                    component: 'select',
                    options: text.options,
                    defaultValue: text.value.h6,
                  },
                  {
                    label: 'Text Margin',
                    name: 'bodySpace',
                    component: 'select',
                    options: margin.options,
                    defaultValue: margin.value.none,
                  },
                ],
              },
              {
                label: 'Icons',
                name: 'icons',
                description:
                  'Customize the icons and their descriptions \n (max 6)',
                component: 'group-list',
                itemProps: {
                  label: '{{item.text}}',
                },
                defaultItem: {
                  text: 'Icon Description',
                  textColor: color.value.black,
                  icon: icons.value.Wrinkle,
                },
                validate: {
                  maxItems: 6,
                },
                // each icon
                fields: [
                  {
                    label: 'Icon',
                    name: 'icon',
                    component: 'select',
                    options: icons.options,
                    defaultValue: icons.value.preShrunk,
                  },
                  {
                    label: 'Text',
                    name: 'text',
                    component: 'text',
                    defaultValue: 'Pre-Shrunk',
                  },
                  {
                    label: 'Text color',
                    name: 'textColor',
                    component: 'select',
                    options: color.options,
                    defaultValue: color.value.black,
                  },
                ],
                defaultValue: [
                  {
                    text: 'Pre-Shrunk',
                    textColor: color.value.black,
                    icon: icons.value.preShrunk,
                  },
                  {
                    text: 'Wrinkle Resistant',
                    textColor: color.value.black,
                    icon: icons.value.wrinkleFree,
                  },
                  {
                    text: 'Tailored Fit',
                    textColor: color.value.black,
                    icon: icons.value.fourWayStretch,
                  },
                ],
              },
            ],
          },
          {
            label: 'Cta Settings',
            name: 'ctaSettings',
            description: 'Set the button link',
            component: 'group',
            fields: [
              {
                label: 'Link (optional)',
                name: 'link',
                description: 'Set optional button link',
                component: 'group-list',
                itemProps: {
                  label: '{{item.cta.text}}',
                },
                defaultItem: {
                  cta: {
                    text: 'Shop now',
                    url: '/',
                    newTab: false,
                  },
                  showButton: true,
                  ctaStyle: button.value.primary,
                },
                validate: {
                  maxItems: 2,
                },
                fields: [
                  {
                    label: 'Show Button',
                    name: 'showButton',
                    component: 'toggle',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: false,
                  },
                  {
                    label: 'Link',
                    name: 'cta',
                    component: 'link',
                  },
                  {
                    name: 'ctaStyle',
                    label: 'Link Style',
                    component: 'select',
                    options: button.options,
                  },
                ],
              },
              {
                label: 'Show Product',
                name: 'showProduct',
                component: 'toggle',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
                defaultValue: false,
              },
              {
                label: 'Button text',
                name: 'buttonText',
                component: 'text',
              },
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
          },
          {
            label: 'Content alignment (mobile)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content alignment (desktop)',
            name: 'alignmentDt',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          alignment: align.value.left,
          alignmentDt: align.value.left,
          text: {
            title:
              '**“The texture is buttery soft and amazing to the touch.”**',
            titleColor: color.value.black,
            subtitle:
              'Cum sociis natoque penatibus et magnis  montes, nascetur ridiculus mus. Maecenas sed diam eget risus varius blandit sit amet non magna.',
            subtitleColor: color.value.gray_dark,
          },
          body: {
            contentType: 'icons',
            text: {
              bodyText:
                ' classic curve hem \n • Elevated regular bottom cut \n • Curved hem to eliminate bunching around the waist \n • Traditional fitting body',
            },
            icons: [
              {
                text: 'Pre-Shrunk',
                textColor: color.value.black,
                icon: icons.value.preShrunk,
              },
              {
                text: '4-Way Stretch',
                textColor: color.value.black,
                icon: icons.value.fourWayStretch,
              },
              {
                text: 'Wrinkle Resistant',
                textColor: color.value.black,
                icon: icons.value.wrinkleFree,
              },
              {
                text: 'Buttery Soft',
                textColor: color.value.black,
                icon: icons.value.butterySoft,
              },
              {
                text: 'Anti-Pilling',
                textColor: color.value.black,
                icon: icons.value.antiPilling,
              },
              {
                text: 'Tailored Fit',
                textColor: color.value.black,
                icon: icons.value.fourWayStretch,
              },
            ],
          },
        },
      },
      section,
    ],
  };
};
