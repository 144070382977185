import { create } from '@theme/create';
import { hexToRgbA } from '@utils';

export const themed = create(
  new (function () {
    this.slide = {
      height: '100%',
      position: 'relative',
      width: '100%',
    };

    this.picture = ({ overlayActive, overlayColor, overlayOpacity }) => ({
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      ':after': {
        content: "''",
        position: 'absolute',
        background: overlayActive
          ? hexToRgbA(overlayColor, overlayOpacity / 100)
          : 'transparent',
        borderRadius: '5px',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    });

    this.contentWrapper = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      pointerEvents: 'none',
      position: 'relative',
      width: '100%',
    };

    this.content = {
      px: [12, 15],
      py: [15, 17],
      pointerEvents: 'auto',
    };

    this.superheading = {
      variant: 'text.label',
    };

    this.heading = {
      variant: 'text.heading',
      m: 0,
    };

    this.subheading = {
      variant: 'text.body',
    };

    this.buttons = {
      mt: [8, 11],
      gridAutoFlow: 'column',
      gridGap: 9,
      '@media only screen and (max-width: 479px)': {
        gridAutoFlow: 'row',
        gridGap: 6,
      },
    };
    this.button = {
      minWidth: ['100px', '120px'],
      width: 'fit-content',
    };
  })()
);
