import { Container, Heading, Button, Spinner, Box, Flex } from 'theme-ui';
import dynamic from 'next/dynamic';

import { useProduct } from '@backpackjs/storefront';

import { useProductRecommendations } from '@hooks';
import { pxWithFullBleed, convertAlignToFlex } from '@utils/common';
import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ProductRecs.schema';
import { themed } from './ProductRecs.theme';

const ProductSwiper = dynamic(
  () => import('@snippets').then((module) => module.ProductSwiper),
  { ssr: false, suspense: false }
);

export const ProductRecs = withInView(
  themed(({ theme, cms, inView }) => {
    const {
      heading,
      textAlign,
      limit,
      ctas,
      section,
      tag,
      slidesPerViewMobile,
    } = cms;
    const product = useProduct();
    const { recommendations } = useProductRecommendations({
      tag: tag || 'customers-also-bought',
      limit,
      productId: product?.legacyResourceId || '',
    });
    if (recommendations && !recommendations.length) return null;

    const singleSlideOnMobile = slidesPerViewMobile === 'one';

    return (
      <Section section={section}>
        <Container data-comp={ProductRecs.displayName} sx={theme.wrapper}>
          <Heading
            sx={{
              ...theme.heading,
              textAlign,
              ...pxWithFullBleed(section),
            }}
          >
            {heading}
          </Heading>
          <Box sx={theme.content}>
            {inView && recommendations?.length > 0 ? (
              <ProductSwiper
                products={recommendations}
                isProductRecs
                singleSlideOnMobile={singleSlideOnMobile}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '500px',
                }}
              >
                <Spinner sx={theme.spinner} />
              </Box>
            )}
          </Box>

          {ctas?.[0]?.cta?.text && (
            <Flex
              sx={{
                ...pxWithFullBleed(section),
                justifyContent: convertAlignToFlex(ctas[0].align),
              }}
            >
              <Button
                href={ctas[0].cta.href}
                variant={`buttons.${ctas[0].style || 'primary'}`}
                sx={{
                  mt: [10, 12],
                }}
              >
                {ctas[0].cta.text}
              </Button>
            </Flex>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductRecs.displayName = 'ProductRecs';
ProductRecs.Schema = Schema;
