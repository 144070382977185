import { useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

import { Box, Flex, Image } from 'theme-ui';

import { themed } from './Slider.theme';

import { TestimonialSlide } from '../Testimonial/TestimonialSlide';

export const Slider = themed(
  ({ theme, testimonials, slider, backgroundColor }) => {
    const [swiper, setSwiper] = useState();

    const [activeIndex, setActiveIndex] = useState(0);

    const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
      if (!swiper || testimonials?.length < 2) return;
      swiper?.on('slideChange', () => {
        setActiveIndex(swiper?.realIndex);
      });
    }, [swiper, testimonials?.length]);

    useEffect(() => {
      setActiveIndexAndSlideToOnSlideChange();
    }, [swiper, testimonials?.length]);

    return (
      <Box
        data-comp={Slider.displayName}
        sx={{
          '.swiper-pagination-bullet-active': {
            bg: slider?.activeNavigationColor || 'gray.medium',
            width: '0.75rem',
            height: '0.75rem',
          },
          '.swiper-button-prev, .swiper-button-next': {
            color: slider?.activeNavigationColor || 'gray.light',
            width: '0.5rem',
            height: '0.5rem',
          },
        }}
      >
        <Swiper
          init={false}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          preloadImages={false}
          fadeEffect={{ crossFade: true }}
          onSwiper={setSwiper}
          slidesPerView={1}
          freeMode
          loopedSlides={1}
          initialSlide={0}
          threshold={25}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
          centeredSlides
          effect={slider?.effect === 'fade' ? 'fade' : null}
          autoplay={
            slider?.autoplay
              ? { delay: slider.delay || 5000, disableOnInteraction: true }
              : false
          }
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
        >
          {testimonials?.map((slide, index) => (
            <SwiperSlide key={index}>
              <TestimonialSlide
                media={slide.media}
                text={slide.testimonialText}
                stars={slide.stars}
                location={slide.locationDt}
                visible={slider?.effect !== 'fade' || index === activeIndex}
                isActiveSlide={index === activeIndex}
                isFirstSlide={index === 0}
              />
            </SwiperSlide>
          ))}
          <Box className="swiper-pagination" sx={theme.bullets} />
          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'end',
              backgroundColor,
              height: '30px',
              width: '100%',
            }}
          />
        </Swiper>
      </Box>
    );
  }
);

Slider.displayName = 'Slider';
