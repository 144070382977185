import { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Button, Flex, Text } from 'theme-ui';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { useAddToCart } from '@templates/product/Main/Details/AddToCart/useAddToCart';

import { themed } from './QuickAdd.theme';

export const QuickAdd = themed(({ theme, selectedProduct, props }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);

  const { addToCart } = useAddToCart({
    selectedVariant,
    product: selectedProduct,
  })[1];

  const preOrder = selectedProduct?.tags?.includes('pre-order');

  const variants = useMemo(() => {
    return selectedProduct?.variants
      ? [...selectedProduct.variants].sort((a, b) => a.position - b.position)
      : [];
  }, [selectedProduct?.id]);

  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedProduct?.handle,
    withInventory: false,
  });

  const addItem = useCallback(async () => {
    await addToCart();
  }, [selectedVariant]);

  useEffect(() => {
    addItem();
  }, [selectedVariant]);

  const hideQuickAdd = useMemo(() => {
    return selectedProduct?.tags?.includes('hide-quick-add');
  }, [selectedProduct?.id]);

  if (hideQuickAdd) return null;

  return (
    <Flex data-comp={QuickAdd.displayName} sx={theme.container} {...props}>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          opacity: 1,
          transition: 'opacity 300ms ease 0s',
          '@media screen and (min-width: 480px)': {
            ':hover': { opacity: 0.0 },
          },
        }}
      >
        <Text variant="text.sm" sx={theme.firstText}>
          {preOrder ? 'Pre-order' : '+ Quick Add'}
        </Text>
      </Box>

      <Flex sx={theme.sizeContainer}>
        {variants.map((variant, index) => {
          const isLast = index === variant.length - 1;
          const size =
            variant.selectedOptionsMap.Size ||
            variant.selectedOptionsMap.Waist ||
            variant.selectedOptionsMap.Amount;
          const variantInventory = inventory?.variants?.[variant?.id];
          const variantIsSoldOut =
            inventoryFetched && !variantInventory?.availableForSale;
          return (
            <Button
              key={index}
              aria-label={`Select Size: ${size}`}
              disabled={variantIsSoldOut}
              variant="buttons.secondary"
              onClick={() => {
                if (variant?.id === selectedVariant?.id) {
                  addToCart();
                } else {
                  setSelectedVariant(variant);
                }
              }}
              sx={theme.button(isLast, variantIsSoldOut)}
            >
              {size || 'Add to Cart'}
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
});

QuickAdd.displayName = 'QuickAdd';
