import React from 'react';
import { withInView } from '@utils';
import { Markdown, Svg, Section, Picture } from '@snippets';
import { Container, Paragraph, Flex, Box } from 'theme-ui';
import { Schema } from './IconRow.schema';
import { themed } from './IconRow.theme';

export const IconRow = withInView(
  themed(({ theme, cms }) => {
    const { heading, description, icons, section } = cms;

    return (
      <Section section={section}>
        <Container data-comp={IconRow.displayName} sx={theme.container}>
          {heading && (
            <Flex sx={theme.outer}>
              {heading && <Paragraph sx={theme.heading}>{heading}</Paragraph>}
              {description && (
                <Markdown text={description} textSx={theme.description} />
              )}
            </Flex>
          )}
          {icons.length > 0 && (
            <Box sx={theme.icons}>
              {icons.map((icon, index) => {
                const svgName = icon?.icon?.split('#').pop();

                return (
                  <Flex key={index} sx={theme.iconWrap}>
                    {icon?.image?.src ? (
                      <Picture
                        images={[
                          {
                            src: icon.image.src,
                            ratio: 1,
                          },
                        ]}
                        sx={theme.icon}
                      />
                    ) : (
                      <Svg
                        src={`/svgs/noprecache/fabric/${svgName}.svg#${svgName}`}
                        alt={icon.icon}
                        viewBox="0 0 360 360"
                        sx={theme.icon}
                      />
                    )}
                    {icon.text && (
                      <Markdown text={icon.text} textSx={theme.text} />
                    )}
                  </Flex>
                );
              })}
            </Box>
          )}
        </Container>
      </Section>
    );
  })
);
IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
