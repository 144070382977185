import dynamic from 'next/dynamic';
import { Container, Flex, Grid } from 'theme-ui';
import { useInView } from 'react-intersection-observer';

import { CountrySelector, SocialLinks, Svg } from '@snippets';
import { Link } from '@snippets/Link';

import { themed } from './Footer.theme';

const DesktopMenu = dynamic(
  () => import('./Desktop').then((mod) => mod.DesktopMenu),
  { ssr: false, suspense: false }
);
const MobileMenu = dynamic(
  () => import('./Mobile').then((mod) => mod.MobileMenu),
  { ssr: false, suspense: false }
);
const Legal = dynamic(() => import('./Legal').then((mod) => mod.Legal), {
  ssr: false,
  suspense: false,
});
const EmailSignUpSocial = dynamic(
  () => import('./EmailSignUpSocial').then((mod) => mod.EmailSignUpSocial),
  { ssr: false, suspense: false }
);
const StoreDropdown = dynamic(
  () => import('./StoreDropdown').then((mod) => mod.StoreDropdown),
  { ssr: false, suspense: false }
);

export const Footer = themed(({ theme }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '400px',
  });

  return (
    <Container
      data-comp={Footer.displayName}
      as="footer"
      ref={ref}
      sx={theme.wrapper}
    >
      {inView && (
        <Container sx={theme.content}>
          <Grid sx={theme.grid}>
            <DesktopMenu />

            <MobileMenu />

            <EmailSignUpSocial dark />

            <Grid columns={[2, 4]}>
              {/* <StoreDropdown /> */}
              <CountrySelector maxHeight={150} />
            </Grid>
          </Grid>

          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: ['column', 'row'],
            }}
          >
            {/* TODO: this might be replace with the ICON component when the SVG is ready */}
            <Flex
              sx={{
                alignItems: 'center',
              }}
            >
              <Link href="/">
                <Svg
                  alt="Cuts Logo"
                  viewBox="0 0 338 338"
                  src="/svgs/logo.svg#logo"
                  sx={{
                    color: 'white',
                    width: '48px',
                    height: '48px',
                  }}
                />
              </Link>
              <SocialLinks sx={theme.socialLinks} />
            </Flex>
            <Legal />
          </Flex>
        </Container>
      )}
    </Container>
  );
});

Footer.displayName = 'Footer';
