import dynamic from 'next/dynamic';
import { Container, Box } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './HalfMediaVIP.schema';
import { themed } from './HalfMedia.theme';

const Content = dynamic(
  () => import('./Content').then((module) => module.Content),
  { ssr: false, suspense: false }
);

const Media = dynamic(() => import('./Media').then((module) => module.Media), {
  ssr: false,
  suspense: false,
});

const selectMediaLocation = (media) => {
  if (!media) return [undefined, undefined];
  const { mediaType, video, image } = media;
  return mediaType === 'video'
    ? [video?.locationDt, video?.location]
    : [image?.locationDt, image?.location];
};

export const HalfMediaVIP = withInView(
  themed(({ theme, cms, inView }) => {
    const { media, content, section } = cms;
    const mediaLeft = selectMediaLocation(media)[0] === 'left'; // mobile
    const mediaTop = selectMediaLocation(media)[1] === 'top'; // desktop
    const hasRoundImage = !!media?.image?.roundImage;

    return (
      <Section section={section}>
        <Container
          data-comp={HalfMediaVIP.displayName}
          sx={{
            ...theme.grid,
            gridTemplateAreas: [
              mediaTop ? '"media" "content"' : '"content" "media"',
              mediaLeft ? '"media content"' : '"content media"',
            ],
          }}
        >
          <Box
            sx={{
              ...theme.media,
              ':before': {
                content: '""',
                float: 'left',
                pb: [media?.image?.ratio || '100%', 0],
              },
            }}
          >
            {inView && <Media media={media} />}
          </Box>

          <Box
            sx={{
              ...theme.content(hasRoundImage),
              justifyContent: mediaLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
                pb: [0, media?.image?.ratioDt || '100%'],
              },
            }}
          >
            {inView && (
              <Content content={content} hasRoundImage={hasRoundImage} />
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

HalfMediaVIP.displayName = 'HalfMedia';
HalfMediaVIP.Schema = Schema;
