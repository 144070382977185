import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
    };

    this.heading = {
      variant: 'text.h3',
      mb: [10, 12],
    };

    this.content = {
      position: 'relative',
      width: '100%',
      minHeight: '150px',
    };

    this.spinner = {
      width: '30px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
