import { useEffect, useMemo } from 'react';
import { Flex, Text } from 'theme-ui';

import { Link } from '@snippets';

import { SwatchesContainer } from '../Swatches';

import { themed } from './Details.theme';
import { Price } from '../Price';

export const Details = themed(
  ({
    theme,
    initialProduct,
    swatches,
    handleClick,
    selectedVariant,
    selectedProduct,
    setProductFromColorSelector,
    setVariantFromColorSelector,
    productUrl,
  }) => {
    const title = selectedProduct?.title?.split('|')[0];

    const colorName = selectedProduct?.optionsMap?.color?.[0]?.toLowerCase();

    const showColorName = useMemo(() => {
      return colorName
        ? Object.keys(swatches).some((color) => color === colorName)
        : null;
    }, [colorName, swatches]);

    const colorGroupsMap = useMemo(() => {
      if (!initialProduct) return null;
      return initialProduct?.grouping?.isTransformed
        ? initialProduct.grouping.colorGroupsMap
        : initialProduct?.colorGroupsMap;
    }, [initialProduct?.id]);

    const showSwatches = useMemo(() => {
      if (!colorGroupsMap) return false;
      return Object.keys(colorGroupsMap).length > 0;
    }, [colorGroupsMap]);

    // sets initial variant from initial color
    useEffect(() => {
      if (!initialProduct || !showSwatches) return;
      setProductFromColorSelector(initialProduct);
      setVariantFromColorSelector(initialProduct.variants?.[0]);
    }, [initialProduct?.id, showSwatches]);
    const useBlackColor =
      selectedProduct?.tags?.includes('bundle') ||
      selectedProduct?.tags?.includes('grey-strikethrough');
    return (
      <Flex data-comp={Details.displayName} sx={theme.wrapper}>
        {showColorName && <Text sx={theme.colorName}>{colorName}</Text>}

        {/* Title */}
        <Link
          href={productUrl}
          tabIndex="-1"
          sx={theme.title}
          onClick={handleClick}
        >
          {title}
        </Link>

        {/* Price */}
        <Price
          selectedVariant={selectedVariant}
          useBlackColor={useBlackColor}
          theme={theme}
        />

        {swatches && showSwatches && selectedVariant && (
          <SwatchesContainer
            swatches={swatches}
            selectedVariant={selectedVariant}
            setProductFromColorSelector={setProductFromColorSelector}
            setVariantFromColorSelector={setVariantFromColorSelector}
            colorGroupsMap={colorGroupsMap}
            initialProduct={initialProduct}
          />
        )}
      </Flex>
    );
  }
);

Details.displayName = 'Details';
