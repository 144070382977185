import forgot from './forgot';
import login from './login';
import menu from './menu';
import register from './register';
import reset from './reset';
import rewards from './rewards';

// account settings
export default {
  label: 'Account Settings',
  name: 'account',
  component: 'group',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'My account',
    },
    menu,
    forgot,
    {
      name: 'loginRegisterHeading',
      label: 'Login - Register Heading',
      component: 'text',
      defaultValue: 'Welcome!',
    },
    login,
    register,
    reset,
    rewards,
  ],
};
