import { section, icons } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Icon Row',
    key: 'icon-row',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'Heading',
      },
      {
        name: 'description',
        label: 'Description',
        component: 'markdown',
        defaultValue: 'Description...',
      },
      {
        name: 'icons',
        label: 'Icons',
        component: 'group-list',
        itemProps: (item) => {
          return {
            label: item?.text || 'Default Title',
          };
        },
        defaultItem: () => ({
          text: '**Breathable**',
          icon: icons.value.breathable,
        }),
        fields: [
          {
            label: 'Image',
            name: 'image',
            description:
              'Choose or upload image file. If no image src is present, section will render icons from list below.',
            component: 'image',
            parse: (image) => image,
          },
          {
            name: 'icon',
            label: 'Icon',
            description:
              'If no image src chosen above, section will render iconst from this list.',
            component: 'select',
            options: icons.options,
          },
          {
            name: 'text',
            label: 'Text',
            component: 'markdown',
            defaultValue: 'Breathable',
          },
        ],
        defaultValue: [
          {
            text: '**Breathable**',
            icon: icons.value.breathable,
          },
          {
            text: '**Water Resistant**',
            icon: icons.value.waterResistant,
          },
          {
            text: '**Buttery Soft**',
            icon: icons.value.butterySoft,
          },
        ],
      },
      section,
    ],
  };
};
